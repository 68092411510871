import React from "react";

function VideoBNB() {
  return (
    <div className="resp-video">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/TQ_msIUJnao" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  )
}

export default VideoBNB